import "./Footer.scss";
import whiteLogo from "../../../assets/logos/just-logo-white.png";
import whiteCCLogo from "../../../assets/logos/coastal-carbon-full-logo-white.png";
import mobileDivider from "../../../assets/design-vectors/divider-mobile.png";
import tabletDivider from "../../../assets/design-vectors/divider-tablet.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer__top-container">
          <Link to="/" className="footer__footer-logo">
            <img src={whiteLogo} alt="" className="footer__footer-logo-image" />
            <div className="footer__footer-logo-text">
              <div className="footer__footer-logo-text-title">KelpSentinel</div>
              <div className="footer__footer-logo-text-subtitle">
                Powered by Coastal Carbon
              </div>
            </div>
          </Link>
          <div className="footer__links">
            <a href="/#about" className="footer__link">
              About
            </a>
            <a href="/#team" className="footer__link">
              Our Team
            </a>
            <Link to="/map" className="footer__link footer__link-button">
              Map
            </Link>
          </div>
          <div className="footer__socials">
            <a
              href="https://coastalcarbon.ai/"
              target="_blank"
              className="footer__link"
            >
              <img
                src={whiteCCLogo}
                alt=""
                className="footer__footer-cc-logo"
              />
            </a>
          </div>
        </div>
        <img
          src={mobileDivider}
          alt="Dividing Line"
          className="footer__divider-mobile"
        />
        <img
          src={tabletDivider}
          alt="Dividing Line"
          className="footer__divider-tablet"
        />
        <div className="footer__bottom-container">
          <div className="footer__legal">&copy; 2024 Coastal Carbon</div>
          <Link
            to="https://open.esa.int/image-usage-creative-commons/"
            target="_blank"
          >
            <div className="footer__legal">Sentinel-2 Licence Information</div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
