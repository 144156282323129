import "./ListContent.scss";
import kelpLeaves from "../../../assets/transparent/kelp-leaves.png";
import listIcon from "../../../assets/design-vectors/satellite-list-icon.png";
import { useInView } from "react-intersection-observer";

const Section = ({ children, animation }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`hidden-section animate__animated ${
        inView ? `visible-section ${animation}` : ""
      }`}
      style={{ animationDuration: "3s", animationDelay: "0.25s" }}
    >
      {children}
    </div>
  );
};

function ListContent() {
  return (
    <>
      <div className="list-content">
        <div className="list-content__image-container">
          <img
            src={kelpLeaves}
            alt="Kelp Leaves"
            className="list-content__image"
          />
        </div>
        <div className="list-content__container">
          <Section
            animation="animate__fadeIn"
            className="list-content__list-item"
          >
            <img
              src={listIcon}
              alt="List Icon"
              className="list-content__item-bullet"
            />
            <div className="list-content__item-text">
              <div className="list-content__item-title">
                KelpSentinel Harnesses Satellite Technology
              </div>
              <div className="list-content__item-sentence">
                To pinpoint and assess floating kelp, providing a global
                perspective on these crucial marine habitats.
              </div>
            </div>
          </Section>
          <Section
            animation="animate__fadeIn"
            className="list-content__list-item"
          >
            <img
              src={listIcon}
              alt="List Icon"
              className="list-content__item-bullet"
            />
            <div className="list-content__item-text">
              <div className="list-content__item-title">
                Kelp & Climate Change
              </div>
              <div className="list-content__item-sentence">
                Kelp forests play a significant role in combating climate change
                by absorbing atmospheric CO2; monitoring their health and extent
                is crucial for assessing their impact and ensuring their
                protection.
              </div>
            </div>
          </Section>
          <Section
            animation="animate__fadeIn"
            className="list-content__list-item"
          >
            <img
              src={listIcon}
              alt="List Icon"
              className="list-content__item-bullet"
            />
            <div className="list-content__item-text">
              <div className="list-content__item-title">
                Global Monitoring Made Easy
              </div>
              <div className="list-content__item-sentence">
                KelpSentinel harnesses the power of the ESA's Sentinel-2
                satellites, capturing the Earth every five days with
                high-resolution multispectral imagery, to monitor kelp forests
                worldwide efficiently.
              </div>
            </div>
          </Section>
          <Section
            animation="animate__fadeIn"
            className="list-content__list-item"
          >
            <img
              src={listIcon}
              alt="List Icon"
              className="list-content__item-bullet"
            />
            <div className="list-content__item-text">
              <div className="list-content__item-title">Accelerated by AI</div>
              <div className="list-content__item-sentence">
                Powered by Coastal Carbon’s cutting-edge AI, KelpSentinel is
                able to process imagery 1000 times faster than traditional
                methods, making near-real-time kelp tracking possible worldwide.
              </div>
            </div>
          </Section>
        </div>
      </div>
    </>
  );
}

export default ListContent;
