import "./Testimonials.scss";
import redLine from "../../../assets/design-vectors/red-line.png";
import placeholder from "../../../assets/medium-images/placeholder.png";
import listIcon from "../../../assets/design-vectors/satellite-list-icon.png";

function Testimonials() {
  return (
    <>
      <div className="testimonials">
        <div className="testimonials__container">
          <div className="testimonials__sub-heading">LOREM IPSUM DOLOR</div>
          <div className="testimonials__heading">What our customers say</div>
          <div className="testimonials__quote-box">
            <img
              src={redLine}
              alt="Vertical Red Line"
              className="testimonials__vertical-line"
            />
            <div className="testimonials__paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              iaculis ac justo vitae ornare. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </div>
          </div>
        </div>
        <div className="testimonials__cards">
          <div className="testimonials__card">
            <div className="testimonials__quote">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              iaculis ac justo vitae ornare. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </div>
            <div className="testimonials__profile">
              <img
                src={placeholder}
                alt="Placeholder Image"
                className="testimonials__profile-image"
              />
              <div className="testimonials__profile-text">
                <div className="testimonials__profile-name">Victoria</div>
                <div className="testimonials__profile-title">Lorem ipsum</div>
              </div>
            </div>
          </div>
          <div className="testimonials__card">
            <div className="testimonials__quote">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              iaculis ac justo vitae ornare. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </div>
            <div className="testimonials__profile">
              <img
                src={placeholder}
                alt="Placeholder Image"
                className="testimonials__profile-image"
              />
              <div className="testimonials__profile-text">
                <div className="testimonials__profile-name">Victoria</div>
                <div className="testimonials__profile-title">Lorem ipsum</div>
              </div>
            </div>
          </div>
          <div className="testimonials__card">
            <div className="testimonials__quote">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              iaculis ac justo vitae ornare. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </div>
            <div className="testimonials__profile">
              <img
                src={placeholder}
                alt="Placeholder Image"
                className="testimonials__profile-image"
              />
              <div className="testimonials__profile-text">
                <div className="testimonials__profile-name">Victoria</div>
                <div className="testimonials__profile-title">Lorem ipsum</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
