import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import MapboxMap from "./components/Map/MapboxMap/MapboxMap";
import NotFound from "./pages/NotFound/NotFound";
import Landing from "./pages/Landing/Landing";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Landing />} />
          <Route path="/map" element={<MapboxMap />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
