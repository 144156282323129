import "./OurCompany.scss";
import satellitesAroundEarth from "../../../assets/transparent/satellites-around-earth.png";
function OurCompany() {
  return (
    <>
      <div className="our-company" id="company">
        <div className="our-company__container">
          <div className="our-company__text-container">
            <div className="our-company__title">
              Coastal Carbon: Pioneering Environmental Intelligence
            </div>
            <div className="our-company__paragraph">
              At the forefront of applying artificial intelligence to
              environmental challenges, Coastal Carbon Inc. is dedicated to
              developing innovative solutions that enhance our understanding and
              preservation of the natural world. Through projects like
              KelpSentinel, the company demonstrates its commitment to
              sustainable and accessible environmental monitoring.
            </div>
            <a href="https://coastalcarbon.ai/">
              <button className="our-company__button">Learn More</button>
            </a>
          </div>
          <img
            src={satellitesAroundEarth}
            alt="Satellites Around Earth"
            className="our-company__image"
          />
        </div>
      </div>
    </>
  );
}

export default OurCompany;
