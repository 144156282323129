import "./ImageAndList.scss";
import redLine from "../../../assets/design-vectors/red-line.png";
import { Link } from "react-router-dom";
import listIcon from "../../../assets/design-vectors/satellite-list-icon.png";
import satelliteImageOfCoast from "../../../assets/graphics/satellite-image-of-coast.png";

function ImageAndList() {
  return (
    <>
      <div className="image-and-list">
        <div className="image-and-list__container">
          <div className="image-and-list__heading">From Pixels to Patterns</div>
          <div className="image-and-list__quote-box">
            <img
              src={redLine}
              alt="Vertical Red Line"
              className="image-and-list__vertical-line"
            />
            <div className="image-and-list__paragraph">
              Coastal Carbon’s AI enhances traditional pixel-based analysis
              methods, transforming complex data into actionable insights faster
              than ever before.
            </div>
          </div>
          <Link to="/map">
            <button className="image-and-list__explore-button">
              Explore the Map
            </button>
          </Link>
        </div>
        <img
          src={satelliteImageOfCoast}
          alt="Satellite Image Of Coast"
          className="image-and-list__image"
        />
        <div className="image-and-list__list-blocks">
          <div className="image-and-list__list-block image-and-list__list-block-1">
            <img
              src={listIcon}
              alt="List Icon"
              className="image-and-list__item-bullet"
            />
            <div className="image-and-list__text">
              <div className="image-and-list__title">
                Open Data, Open Opportunities
              </div>
              <div className="image-and-list__sentence">
                Utilizing freely available Sentinel imagery, KelpSentinel
                embodies the spirit of collaboration.
              </div>
            </div>
          </div>
          <div className="image-and-list__list-block image-and-list__list-block-2">
            <img
              src={listIcon}
              alt="List Icon"
              className="image-and-list__item-bullet"
            />
            <div className="image-and-list__text">
              <div className="image-and-list__title">Sustainable Seas</div>
              <div className="image-and-list__sentence">
                By quantifying kelp biomass, KelpSentinel contributes to
                understanding and protecting marine ecosystems.
              </div>
            </div>
          </div>
          <div className="image-and-list__list-block image-and-list__list-block-3">
            <img
              src={listIcon}
              alt="List Icon"
              className="image-and-list__item-bullet"
            />
            <div className="image-and-list__text">
              <div className="image-and-list__title">Science Meets Tech</div>
              <div className="image-and-list__sentence">
                A unique collaboration between AI experts at Coastal Carbon’s AI
                and ocean scientists at MIT WHOI.
              </div>
            </div>
          </div>
          <div className="image-and-list__list-block image-and-list__list-block-4">
            <img
              src={listIcon}
              alt="List Icon"
              className="image-and-list__item-bullet"
            />
            <div className="image-and-list__text">
              <div className="image-and-list__title">Streamlined UI</div>
              <div className="image-and-list__sentence">
                Simplifying the analysis and decision-making process for
                Scientists and conservationists.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageAndList;
