import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import whiteLogo from "../../../assets/logos/just-logo-white.png";

import { Map, NavigationControl, ScaleControl } from "react-map-gl";

import DrawControl from "./draw-control";
import GeocoderControl from "./geocoder-control";

import "./MapboxMap.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const ToggleButton = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="mapboxgl-ctrl-icon mapboxgl-ctrl-btn"
    title="Toggle Basemap" // Tooltip for the button
  >
    {children}
  </button>
);

const MapboxMap = () => {
  const mapRef = useRef();
  const legendRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [features, setFeatures] = useState({});
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [showFullLegend, setShowFullLegend] = useState(false);
  const [monochrome, setMonochrome] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: 33.957281,
    longitude: -120.21274,
    zoom: 13,
  });

  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport);
  }, []);

  const onLoad = useCallback(() => {
    const map = mapRef.current?.getMap();
    if (map) {
      map.flyTo({
        center: [-120.21274, 33.957281],
        zoom: 13,
        speed: 1.6,
        curve: 1,
        easing: (t) => t,
      });

      // Add click event listener to the vector layer
      map.on("click", "vector-layer-id", (e) => {
        if (e.features.length > 0) {
          const feature = e.features[0];
          setSelectedFeature(feature.properties); // Assuming the data you need is in `properties`
        }
      });
    }
  }, [mapRef]);

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const legendItems = [
    { color: "#cfffbd", label: "35 < 1000" },
    { color: "#7bff47", label: "1000 < 1999" },
    { color: "#298d01", label: "2000 < 2999" },
    { color: "#123d01", label: "> 3000" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Update the state based on viewport width
      setShowFullLegend(window.innerWidth > 1024);
    };

    // Setup event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Effect for handling clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (legendRef.current && !legendRef.current.contains(event.target)) {
        setShowFullLegend(false);
      }
    };

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [legendRef]);

  return (
    <>
      <div className="map-container">
        <div className="mobile-map-header">
          <Link to="/" className="map-logo-header">
            <img
              src={whiteLogo}
              alt=""
              className="map-logo-header__map-logo-image"
            />
            <div className="map-logo-header__map-logo-text">
              <div className="map-logo-header__map-logo-text-title">
                KelpSentinel
              </div>
              <div className="map-logo-header__map-logo-text-subtitle">
                Powered by Coastal Carbon
              </div>
            </div>
          </Link>
        </div>
        {!isMobile && (
          <Link to="/" className="map-logo">
            <img src={whiteLogo} alt="" className="map-logo__map-logo-image" />
            <div className="map-logo__map-logo-text">
              <div className="map-logo__map-logo-text-title">KelpSentinel</div>
              <div className="map-logo__map-logo-text-subtitle">
                Powered by Coastal Carbon
              </div>
            </div>
          </Link>
        )}
        <Map
          initialViewState={{
            longitude: 0,
            latitude: 0,
            zoom: 1,
          }}
          style={{ width: "100%", height: "100%" }}
          mapStyle={
            monochrome
              ? "mapbox://styles/jesseuszkay/clxw2lus504jy01qj032097v7"
              : "mapbox://styles/jesseuszkay/clxnopo7602pi01qoecad3ud9"
          }
          mapboxAccessToken={MAPBOX_TOKEN}
          ref={mapRef}
          onLoad={onLoad}
          onMove={(evt) => handleViewportChange(evt.viewState)}
        >
          <GeocoderControl
            mapboxAccessToken={MAPBOX_TOKEN}
            position="top-right"
          />
          <NavigationControl position="top-right" />
          <ScaleControl maxWidth={100} unit="imperial" position="bottom-left" />
          <DrawControl
            position="top-right"
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
            }}
            defaultMode="simple_select"
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
          <ToggleButton onClick={() => setMonochrome((prev) => !prev)}>
            <span
              style={{
                display: "block",
                backgroundPosition: "center",
                width: "100%",
                height: "100%",
              }}
            ></span>
          </ToggleButton>
          <div
            ref={legendRef}
            className="legend"
            id="legend"
            style={{
              position: "absolute",
              bottom: "40px",
              right: "20px",
              width: "auto",
              backgroundColor: "white",
              padding: "20px",
            }}
          >
            <div className="legend__container">
              <h4
                onClick={() => setShowFullLegend((prev) => !prev)}
                style={{
                  cursor: "pointer",
                }}
              >
                Legend
              </h4>
              {showFullLegend && (
                <>
                  <h5>(in kg/100m&sup2;)</h5>
                  <div className="legend__items">
                    {legendItems.map((item, index) => (
                      <div key={index} className="legend__items-row">
                        <span
                          style={{
                            backgroundColor: item.color,
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        ></span>
                        {item.label}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Map>
      </div>
    </>
  );
};

export default MapboxMap;
