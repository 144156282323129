import "./Landing.scss";
import Header from "../../components/LandingPage/Header/Header";
import MainContent from "../../components/LandingPage/MainContent/MainContent";
import AboutContent from "../../components/LandingPage/AboutContent/AboutContent";
import ListContent from "../../components/LandingPage/ListContent/ListContent";
import ImageSectionOne from "../../components/LandingPage/ImageSectionOne/ImageSectionOne";
import ImageSectionTwo from "../../components/LandingPage/ImageSectionTwo/ImageSectionTwo";
import OurTeam from "../../components/LandingPage/OurTeam/OurTeam";
import ImageAndList from "../../components/LandingPage/ImageAndList/ImageAndList";
import Testimonials from "../../components/LandingPage/Testimonials/Testimonials";
import OurCompany from "../../components/LandingPage/OurCompany/OurCompany";
import Footer from "../../components/LandingPage/Footer/Footer";

function Landing() {
  return (
    <div className="landing">
      <Header />
      <div className="landing__content">
        <MainContent />
        <AboutContent />
        <ListContent />
        <ImageSectionOne />
        <ImageSectionTwo />
        <OurTeam />
        <ImageAndList />
        {/* <Testimonials /> */}
        <OurCompany />
        <Footer />
      </div>
    </div>
  );
}

export default Landing;
