import "./OurTeam.scss";
import redLine from "../../../assets/design-vectors/red-line.png";
import don from "../../../assets/team-images/Don.jpeg";
import james from "../../../assets/team-images/JamesL.png";
import jesse from "../../../assets/team-images/Jesse.png";
import kelly from "../../../assets/team-images/Kelly.png";
import mojtaba from "../../../assets/team-images/Mojtaba.png";
import thomas from "../../../assets/team-images/Thomas.jpeg";
import wajahat from "../../../assets/team-images/wajahat.jpg";
import tomBell from "../../../assets/team-images/TomBell.png";
import { useInView } from "react-intersection-observer";

function OurTeam() {
  return (
    <>
      <div className="our-team" id="team">
        <div className="our-team__container">
          <div className="our-team__sub-heading">
            Uniting Science and Innovation for Ocean Health
          </div>
          <div className="our-team__heading">The KelpSentinel Team</div>
          <div className="our-team__quote-box">
            <img
              src={redLine}
              alt="Vertical Red Line"
              className="our-team__vertical-line"
            />
            <div className="our-team__paragraph">
              At the heart of KelpSentinel is a multidisciplinary team of
              scientists, engineers, and innovators from Coastal Carbon Inc. and
              MIT Woods Hole Oceanographic Institute. Together, we blend
              cutting-edge technology with deep ecological insights to
              revolutionize monitoring and understanding of marine ecosystems.
              Our collaborative efforts combine raw science and AI to provide
              real-time, global insights into kelp forests, contributing to
              sustainable ocean management.
            </div>
          </div>
        </div>
        <div className="our-team__profile-cards ">
          <div className="our-team__profile-card">
            <img
              src={kelly}
              alt="Kelly Zheng"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">Kelly Zheng</div>
            <div className="our-team__profile-title">Cofounder and CEO</div>
            <div className="our-team__profile-subtitle">Coastal Carbon</div>
          </div>
          <div className="our-team__profile-card">
            <img
              src={james}
              alt="James Lowman"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">James Lowman</div>
            <div className="our-team__profile-title">
              Founding Engineer, PhD Candidate
            </div>
            <div className="our-team__profile-subtitle">Coastal Carbon</div>
          </div>
          <div className="our-team__profile-card">
            <img
              src={mojtaba}
              alt="Mojtaba Valipour"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">Mojtaba Valipour, PhD</div>
            <div className="our-team__profile-title">Founding Engineer</div>
            <div className="our-team__profile-subtitle">Coastal Carbon</div>
          </div>
          <div className="our-team__profile-card">
            <img
              src={jesse}
              alt="Jesse Uszkay"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">Jesse Uszkay</div>
            <div className="our-team__profile-title">Full Stack Lead</div>
            <div className="our-team__profile-subtitle">Coastal Carbon</div>
          </div>
          <div className="our-team__profile-card">
            <img
              src={don}
              alt="Don Martocello"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">Don Martocello, PhD</div>
            <div className="our-team__profile-title">Oceanography Lead</div>
            <div className="our-team__profile-subtitle">Coastal Carbon</div>
          </div>
          <div className="our-team__profile-card">
            <img
              src={thomas}
              alt="Thomas Storwick"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">Thomas Storwick</div>
            <div className="our-team__profile-title">Cofounder and COO</div>
            <div className="our-team__profile-subtitle">Coastal Carbon</div>
          </div>
          <div className="our-team__profile-card">
            <img
              src={wajahat}
              alt="Wajahat Ali"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">Wajahat Ali</div>
            <div className="our-team__profile-title">Data Scientist</div>
            <div className="our-team__profile-subtitle">Coastal Carbon</div>
          </div>
          <div className="our-team__profile-card">
            <img
              src={tomBell}
              alt="Tom Bell"
              className="our-team__profile-image"
            />
            <div className="our-team__profile-name">Tom Bell, PhD</div>
            <div className="our-team__profile-title">Assistant Scientist</div>
            <div className="our-team__profile-subtitle">MIT WHOI</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurTeam;
