import "./AboutContent.scss";
import redLine from "../../../assets/design-vectors/red-line.png";
import brownKelp from "../../../assets/transparent/brown-kelp.png";
import { Link } from "react-router-dom";

const Section = ({ children, animation }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`hidden-section animate__animated ${
        inView ? `visible-section ${animation}` : ""
      }`}
      style={{ animationDuration: "3s", animationDelay: "0.25s" }}
    >
      {children}
    </div>
  );
};

function AboutContent() {
  return (
    <>
      <div className="about-content" id="about">
        <div className="about-content__container">
          <div className="about-content__image-container">
            <img
              src={brownKelp}
              alt="Brown Kelp"
              className="about-content__image"
            />
          </div>
          <div className="about-content__intro">
            <div className="about-content__sub-heading">
              Using Sentinel-2 & AI
            </div>
            <div className="about-content__heading">Science from Space</div>
            <div className="about-content__quote-box">
              <img
                src={redLine}
                alt="Vertical Red Line"
                className="about-content__vertical-line"
              />
              <div className="about-content__paragraph">
                Coastal Carbon’s AI and Sentinel-2 Satellite imagery are
                combined to find and measure floating kelp from space.
              </div>
            </div>
          </div>
          <div className="about-content__explore-more">
            <div className="about-content__sentence">
              KelpSentinel is an interactive global map displaying floating kelp
              biomass.
            </div>
            <Link to="/map">
              <button className="about-content__button">Explore the Map</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutContent;
