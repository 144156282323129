import "./MainContent.scss";
import fullLogo from "../../../assets/logos/kelp-sentinel-logo-white.png";
import { Link } from "react-router-dom";

function MainContent() {
  return (
    <>
      <div className="main-content">
        <div className="main-content__container">
          <div className="main-content__large-logo">
            <img
              src={fullLogo}
              alt="KelpSentinel Logo"
              className="main-content__large-logo-image"
            />
          </div>
          <a href="https://coastalcarbon.ai/" target="_blank" className="">
            <div className="main-content__paragraph">
              Powered by Coastal Carbon
            </div>
          </a>
          <div className="main-content__buttons-container">
            <Link to="/map">
              <button className="main-content__button">Explore the Map</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainContent;
