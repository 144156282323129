import "./Header.scss";
import burger from "../../../assets/design-vectors/burger.png";
import whiteLogo from "../../../assets/logos/just-logo-white.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Update the state based on viewport width
    };

    // Setup event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 10 && !hasScrolled) {
        setHasScrolled(true);
      } else if (window.scrollY < 10 && hasScrolled) {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  return (
    <>
      <div className={hasScrolled ? "header header-has-scrolled" : "header"}>
        <Link
          to="/"
          className={
            hasScrolled
              ? "header__header-logo header__header-logo-has-scrolled"
              : "header__header-logo"
          }
        >
          <img src={whiteLogo} alt="" className="header__header-logo-image" />
          <div className="header__header-logo-text">
            <div className="header__header-logo-text-title">KelpSentinel</div>
            <div className="header__header-logo-text-subtitle">
              Powered by Coastal Carbon
            </div>
          </div>
        </Link>

        <div className="header__right-side">
          {/* {isMobile && (
            <img src={burger} alt="Menu Icon" className="header__header-menu" />
          )} */}
          {!isMobile && (
            <div className="header__links">
              <a href="/#about" className="header__link">
                About
              </a>
              <a href="/#team" className="header__link">
                Our Team
              </a>
              <a
                href="https://coastalcarbon.ai/"
                target="_blank"
                className="header__link"
              >
                Coastal Carbon
              </a>
              <Link to="/map" className="header__link header__link-button">
                Map
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
