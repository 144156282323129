import "./ImageSectionOne.scss";
import redLine from "../../../assets/design-vectors/red-line.png";
import listIcon from "../../../assets/design-vectors/satellite-list-icon.png";
import { Link } from "react-router-dom";
import kelpSilhouette from "../../../assets/graphics/kelp-silhouette.png";
import digitalPlanet from "../../../assets/graphics/digital-planet.png";
import sentinelTwoArtist from "../../../assets/graphics/sentinel2-artists-rendering.jpg";
import underwaterKelpPortal from "../../../assets/graphics/underwater-kelp-portal.png";
import { useInView } from "react-intersection-observer";

const Section = ({ children, animation }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`hidden-section animate__animated ${
        inView ? `visible-section ${animation}` : ""
      }`}
      style={{ animationDuration: "3s", animationDelay: "0.25s" }}
    >
      {children}
    </div>
  );
};

function ImageSectionOne() {
  return (
    <>
      <div className="image-section-one">
        <div className="image-section-one__image-block">
          <img
            src={kelpSilhouette}
            alt="Kelp Silhouette"
            className="image-section-one__image-one"
          />
          <img
            src={sentinelTwoArtist}
            alt="Sentinel-2 Artist Rendering"
            className="image-section-one__image-two"
          />
          <img
            src={underwaterKelpPortal}
            alt="Underwater Kelp"
            className="image-section-one__image-three"
          />
          <img
            src={digitalPlanet}
            alt="Placeholder Image"
            className="image-section-one__image-four"
          />
        </div>
        <Section
          animation="animate__fadeIn"
          className="image-section-one__text-block"
        >
          <div className="image-section-one__heading">
            Decoding the Depths with Data
          </div>
          <div className="image-section-one__quote-box">
            <img
              src={redLine}
              alt="Vertical Red Line"
              className="image-section-one__vertical-line"
            />
            <div className="image-section-one__paragraph">
              KelpSentinel's AI model not only identifies kelp from space but
              also measures its biomass, providing crucial data for ecological
              and climate studies.
            </div>
          </div>
          <div className="image-section-one__container">
            <div className="image-section-one__list-item">
              <img
                src={listIcon}
                alt="Kelp Leaves"
                className="image-section-one__item-bullet"
              />
              <div className="image-section-one__item-title">
                High-Resolution Imaging
              </div>
            </div>
            <div className="image-section-one__list-item">
              <img
                src={listIcon}
                alt="Kelp Leaves"
                className="image-section-one__item-bullet"
              />
              <div className="image-section-one__item-title">
                Foundational AI Models
              </div>
            </div>
            <div className="image-section-one__list-item">
              <img
                src={listIcon}
                alt="Kelp Leaves"
                className="image-section-one__item-bullet"
              />
              <div className="image-section-one__item-title">
                Real-Time Analysis
              </div>
            </div>
          </div>
          <Link to="/map">
            <button className="image-section-one__explore-button">
              Explore the Map
            </button>
          </Link>
        </Section>
      </div>
    </>
  );
}

export default ImageSectionOne;
