import PropTypes from "prop-types";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useControl } from "react-map-gl";

const DrawControl = ({
  position = "top-left",
  onCreate = () => {},
  onUpdate = () => {},
  onDelete = () => {},
  ...props
}) => {
  useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      const handleDrawCreate = (e) => {
        const data = e.features[0].geometry.coordinates;
        console.log("Polygon Coordinates:", data);
        onCreate(e);
      };

      const handleDrawUpdate = (e) => {
        const data = e.features[0].geometry.coordinates;
        console.log("Polygon Coordinates:", data);
        onUpdate(e);
      };

      const handleDrawDelete = (e) => {
        const data = e.features[0].geometry.coordinates;
        console.log("Polygon Coordinates:", data);
        onDelete(e);
      };

      map.on("draw.create", handleDrawCreate);
      map.on("draw.update", handleDrawUpdate);
      map.on("draw.delete", handleDrawDelete);

      return () => {
        map.off("draw.create", handleDrawCreate);
        map.off("draw.update", handleDrawUpdate);
        map.off("draw.delete", handleDrawDelete);
      };
    },
    ({ map }) => {
      map.off("draw.create", onCreate);
      map.off("draw.update", onUpdate);
      map.off("draw.delete", onDelete);
    },
    {
      position,
    }
  );

  return null;
};

DrawControl.propTypes = {
  position: PropTypes.string,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DrawControl;
