import "./ImageSectionTwo.scss";
import { Link } from "react-router-dom";
import redLine from "../../../assets/design-vectors/red-line.png";
import listIcon from "../../../assets/design-vectors/satellite-list-icon.png";
import robotHumanTouch from "../../../assets/graphics/robot-human-touch-earch.png";
import otterOnKelp from "../../../assets/graphics/otter-on-kelp.png";
import waterOnRocks from "../../../assets/graphics/water-crashing-on-rocks.png";
import satelliteOnEarth from "../../../assets/graphics/satellite-against-earth.png";
import { useInView } from "react-intersection-observer";

const Section = ({ children, animation }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`hidden-section animate__animated ${
        inView ? `visible-section ${animation}` : ""
      }`}
      style={{ animationDuration: "3s", animationDelay: "0.25s" }}
    >
      {children}
    </div>
  );
};

function ImageSectionTwo() {
  return (
    <>
      <div className="image-section-two">
        <div className="image-section-two__image-block">
          <img
            src={robotHumanTouch}
            alt="Robot and Human Touching Fingers"
            className="image-section-two__image-one"
          />
          <img
            src={waterOnRocks}
            alt="Water Crashing On Rocks"
            className="image-section-two__image-two"
          />
          <img
            src={satelliteOnEarth}
            alt="Satellite On Earth"
            className="image-section-two__image-three"
          />
          <img
            src={otterOnKelp}
            alt="Otter on Kelp"
            className="image-section-two__image-four"
          />
        </div>
        <Section
          animation="animate__fadeIn"
          className="image-section-two__text-block"
        >
          <div className="image-section-two__heading">
            Tech-Powered Conservation
          </div>
          <div className="image-section-two__quote-box">
            <img
              src={redLine}
              alt="Vertical Red Line"
              className="image-section-two__vertical-line"
            />
            <div className="image-section-two__paragraph">
              KelpSentinel is powered by Coastal Carbon AI, integrating advanced
              machine learning algorithms with high-frequency satellite data to
              create a robust monitoring system.
            </div>
          </div>
          <div className="image-section-two__container">
            <div className="image-section-two__list-item">
              <img
                src={listIcon}
                alt="Kelp Leaves"
                className="image-section-two__item-bullet"
              />
              <div className="image-section-two__item-title">
                Cutting-Edge Algorithms
              </div>
            </div>
            <div className="image-section-two__list-item">
              <img
                src={listIcon}
                alt="Kelp Leaves"
                className="image-section-two__item-bullet"
              />
              <div className="image-section-two__item-title">
                Scalable Solutions
              </div>
            </div>
            <div className="image-section-two__list-item">
              <img
                src={listIcon}
                alt="Kelp Leaves"
                className="image-section-two__item-bullet"
              />
              <div className="image-section-two__item-title">
                User-Friendly Interface
              </div>
            </div>
          </div>
          <Link to="/map">
            <button className="image-section-two__explore-button">
              Explore the Map
            </button>
          </Link>
        </Section>
      </div>
    </>
  );
}

export default ImageSectionTwo;
